<!-- =========================================================================================
Time        :2020/10/21 14:33:07
Author      :周宇
Email       :zhouyu674896488@gmail.com
Version     :v1.0
File        :settings.vue
Desc        :

(C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
========================================================================================== -->


<template>
  <div id="user-edit-tab-info">
    <div class="vx-card p-24">
	  	<div class="vx-row">
	    	<div class="vx-col w-full">
		  		<!-- Col Header -->
		  		<div class="flex items-end">
						<span class="leading-none font-medium">全局配置信息</span>
		  		</div>
		  		<vs-divider class="mb-0"></vs-divider>
		  		<!-- Col Content -->
		  		<div>
		    		<div class="mt-4" v-for="(item, index) in config" :key="index">
			  			<div class="flex items-center mb-4">
			    			<vs-switch v-model="item.value" />
								<span class="ml-4">{{ item.description }} </span>
			  			</div>
						</div>
		  		</div>
				</div>
	  	</div>
		</div>
  </div>
</template>

<script>

export default {
  data () {
    return {
	  config: null,
	}
  },
  watch: {
    config: {
			handler: function(newVal, oldVal) {
				if (oldVal) {
					for (let i = 0; i < newVal.length; i++) {
						this.$store.dispatch('config/updateItem', newVal[i])
					}
				}
			},
			deep: true,
		}
  },
  created () {
    this.$store.dispatch('config/fetchItem')
	  .then(res => {
	    this.config = res.data
	  })
  }
}
</script>