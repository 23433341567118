<!-- =========================================================================================
Time        :2020/10/21 14:32:19
Author      :周宇
Email       :zhouyu674896488@gmail.com
Version     :v1.0
File        :CellRendererEdu.vue
Desc        :

(C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
========================================================================================== -->


<template>
  <span> {{ text }} </span>
</template>

<script>
export default {
  name: 'CellRendererEdu',
  computed: {
    text () {
      if (this.params.value === 0) return '大专'
      else if (this.params.value === 1) return '本科'
      else if (this.params.value === 2) return '硕士'
      else if (this.params.value === 3) return '博士'
      else return '其他'
    }
  }
}
</script>
