// =========================================================================================
// Time        :2020/10/21 14:30:25
// Author      :周宇
// Email       :zhouyu674896488@gmail.com
// Version     :v1.0
// File        :moduleDataListActions.js
// Desc        :
// 
// (C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
// ========================================================================================== -->


import axios from '@/axios.js'

export default {

  addItem ({ commit }, item) {
    return new Promise((resolve, reject) => {
     axios.post('/rest/v1/File/', item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, {id: response.data.id, file_name: response.data.file_name}))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchFileListItems ({ commit }, obj) {
    return new Promise((resolve, reject) => {
      axios.get('/rest/v1/File/', {
        params: { username: obj.username, flag: obj.flag }
      })
        .then((response) => {
          commit('SET_PRODUCTS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchItem (context, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/rest/v1/File/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchFeature (context, obj) {
    return new Promise((resolve, reject) => {
      axios.get('/rest/v1/Feature/', {params: {name: obj + ''}})
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
