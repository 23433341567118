<!-- =========================================================================================
Time        :2020/10/21 14:33:00
Author      :周宇
Email       :zhouyu674896488@gmail.com
Version     :v1.0
File        :UserView.vue
Desc        :

(C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
========================================================================================== -->


<template>
  <div id="page-user-view">
	
    <vs-alert color="danger" title="User Not Found" :active.sync="user_not_found">
      <span>User record with username: {{ $route.params.username }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{name:'app-user-list'}" class="text-inherit underline">All Users</router-link>
      </span>
    </vs-alert>

    <div id="user-data" v-if="user_data">

      <vx-card title="用户信息" class="mb-base">

        <!-- Avatar -->
        <div class="vx-row">

          <!-- Avatar Col -->
          <div class="vx-col" id="avatar-col">
            <div class="img-container mb-4">
              <img :src="user_data.avatar" class="rounded w-full" />
            </div>
          </div>

          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-1">
            <table>
              <tr>
                <td class="font-semibold">用户名</td>
                <td>{{ user_data.username }}</td>
              </tr>
              <tr>
                <td class="font-semibold">姓名</td>
                <td>{{ user_data.name }}</td>
              </tr>
              <tr>
                <td class="font-semibold">邮箱</td>
                <td>{{ user_data.email }}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 1 -->

          <!-- Information - Col 2 -->
          <div class="vx-col flex-1" id="account-info-col-2">
            <table>
              <tr>
                <td class="font-semibold">状态</td>
                <td>{{ user_data.is_active ? '已激活': '未激活' }}</td>
              </tr>
              <tr>
                <td class="font-semibold">性别</td>
                <td>{{ user_data.sex ? '男': '女' }}</td>
              </tr>
              <tr>
                <td class="font-semibold">电话</td>
                <td>{{ user_data.phone }}</td>
              </tr>
            </table>
          </div>

        </div>

      </vx-card>

      <vx-card v-if="user_data">

        <div slot="no-body" class="tabs-container px-6 pt-6">

          <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
            <vs-tab label="音频" icon-pack="feather" icon="icon-music">
              <div class="tab-text">
                <user-tab-voice class="mt-4" :data="user_data" />
              </div>
            </vs-tab>
            <vs-tab label="面部" icon-pack="feather" icon="icon-video">
              <div class="tab-text">
                <user-tab-face class="mt-4" :data="user_data" />
              </div>
            </vs-tab>
            <vs-tab label="步态" icon-pack="feather" icon="icon-video">
              <div class="tab-text">
                <user-tab-gait class="mt-4" :data="user_data" />
              </div>
            </vs-tab>
          </vs-tabs>

        </div>

      </vx-card>
    </div>
  </div>
</template>

<script>
import UserTabVoice     from './UserTabVoice.vue'
import UserTabFace      from './UserTabFace.vue'
import UserTabGait      from './UserTabGait.vue'
import ECharts          from 'vue-echarts/components/ECharts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/pie'


export default {
  components: {
    UserTabVoice,
    UserTabFace,
    UserTabGait,
    ECharts
  },
  data () {
    return {
      user_data: null,
      user_not_found: false,
      activeTab: 0,
      pie: {
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            orient: 'vertical',
            left: 'left',
            data: ['Direct interview', 'Email marketing', 'Alliance advertising', 'Video ad', 'Search engine']
        },
        series: [{
            name: 'Access source',
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            color: ['#FF9F43', '#28C76F', '#EA5455', '#87ceeb', '#7367F0'],
            data: [
                { value: 335, name: 'Direct interview' },
                { value: 310, name: 'Email marketing' },
                { value: 234, name: 'Alliance advertising' },
                { value: 135, name: 'Video ad' },
                { value: 1548, name: 'Search engine' }
            ],
            itemStyle: {
                emphasis: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }]
      },
    }
  },
  created () {
    const username = this.$route.params.username
    this.$store.dispatch('userManagement/fetchUser', username)
      .then(res => { this.user_data = res.data })
      .catch(err => {
        if (err.response.status === 404) {
          this.user_not_found = true
          return
        }
        console.error(err) 
      })
  }
}

</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width:370px) {
          display: block;
        }
      }
    }
  }
}

// #account-info-col-1 {
//   // flex-grow: 1;
//   width: 30rem !important;
//   @media screen and (min-width:1200px) {
//     & {
//       flex-grow: unset !important;
//     }
//   }
// }


@media screen and (min-width:1201px) and (max-width:1211px),
only screen and (min-width:636px) and (max-width:991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

  // #account-manage-buttons {
  //   width: 12rem !important;
  //   flex-direction: column;

  //   > button {
  //     margin-right: 0 !important;
  //     margin-bottom: 1rem;
  //   }
  // }

}

</style>
