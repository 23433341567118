// =========================================================================================
// Time        :2020/10/21 14:34:32
// Author      :周宇
// Email       :zhouyu674896488@gmail.com
// Version     :v1.0
// File        :globalComponents.js
// Desc        :
// 
// (C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
// ========================================================================================== -->


import Vue from 'vue'
import VxCard from './components/vx-card/VxCard.vue'
import VxBreadcrumb from './layouts/components/VxBreadcrumb.vue'
import FeatherIcon from './components/FeatherIcon.vue'
import VueJsRecord from './components/VideoJSRecord.vue'
import AudioWaveSufer from './components/AudioWaveSufer.vue'

Vue.component(VxCard.name, VxCard)
Vue.component(VxBreadcrumb.name, VxBreadcrumb)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(VueJsRecord.name, VueJsRecord)
Vue.component(AudioWaveSufer.name, AudioWaveSufer)


// v-select component
import vSelect from 'vue-select'

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('feather-icon', {
      props: {
        icon: 'XIcon',
        svgClasses: 'w-4 h-4 mt-1'
      }
    })
  },
  OpenIndicator: {
    render: createElement => createElement('feather-icon', {
      props: {
        icon: 'ChevronDownIcon',
        svgClasses: 'w-5 h-5'
      }
    })
  }
})

Vue.component(vSelect)
