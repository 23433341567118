// =========================================================================================
// Time        :2020/10/21 14:22:22
// Author      :周宇
// Email       :zhouyu674896488@gmail.com
// Version     :v1.0
// File        :index.js
// Desc        :
// 
// (C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
// ========================================================================================== -->


import axios from '../../../axios/index.js'
import store from '../../../../store/store.js'
import router from '@/router'

// Token Refresh
let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched (access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber (callback) {
  subscribers.push(callback)
}

export default {
  init () {
    axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      // const { config, response: { status } } = error
      const { config, response } = error
      const originalRequest = config
      if (response && response.status === 401) {
        // localStorage.removeItem('accessToken')
        // localStorage.removeItem('refreshToken')
        // localStorage.removeItem('userInfo')
        localStorage.clear()
        router.replace({
          path: '/sign-in',
          query: {
            redirect: router.currentRoute.fullPath
          }
        })
      }
      if (response && response.status === 403) {

        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true
          store.dispatch('auth/fetchAccessToken')
            .then((response) => {
              isAlreadyFetchingAccessToken = false
              onAccessTokenFetched(response.data.access)
            })
        }

        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber(access_token => {
            originalRequest.headers.Authorization = `Bearer ${access_token}`
            resolve(axios(originalRequest))
          })
        })
        return retryOriginalRequest
      }
      if (error.message === 'Network Error') {
        router.replace({
          path: '/maintenance',
          query: {
            redirect: router.currentRoute.fullPath
          }
        })
      }
      return Promise.reject(error)
    })
  },
  login (email, pwd) {
    return axios.post('/rest/v1/token/', {
      username: email,
      password: pwd
    })
  },
  refreshToken () {
    return axios.post('/rest/v1/refresh/', {refresh: localStorage.getItem('refreshToken')})
  }
}
