// =========================================================================================
// Time        :2020/10/21 14:31:26
// Author      :周宇
// Email       :zhouyu674896488@gmail.com
// Version     :v1.0
// File        :moduleUserManagementMutations.js
// Desc        :
// 
// (C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
// ========================================================================================== -->


export default {
  SET_USERS (state, users) {
    state.users = users
  },
  SET_GROUPS (state, groups) {
    state.groups = groups
  }
}
