<!-- =========================================================================================
Time        :2020/10/21 14:21:55
Author      :周宇
Email       :zhouyu674896488@gmail.com
Version     :v1.0
File        :htmlToPdf.vue
Desc        :

(C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
========================================================================================== -->


<script>
// 下面两个package要单独安装
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'

export default{
  install (Vue, options) {
    Vue.prototype.getPdf = function (id, title) {
	  window.pageYOffest = 0
	  document.documentElement.scrollTop = 0
	  document.body.scrollTop = 0
    html2Canvas(document.querySelector(`#${id}`), {
      scale: 4, 									//缩放比例，提高审成果图片清晰度
      useCORS:true,								//允许加载跨域的图片
      allowTaint: false,							//允许图片跨域，和useCORS二者不可共同使用
      tainttest: true,							//监测每张图片是否都加载完成
      logging: true								//日志开关，发布的时候记得改成false
    }).then(function (canvas) {
          let contentWidth = canvas.width
          let contentHeight = canvas.height
          let pageHeight = contentWidth / 592.28 * 841.89
          let leftHeight = contentHeight
          let position = 0
          let imgWidth = 555.28
          let imgHeight = 555.28 / contentWidth * contentHeight
          let pageData = canvas.toDataURL('image/jpeg', 1.0)
          let PDF = new JsPDF('', 'pt', 'a4')
          if (leftHeight < pageHeight) {
              PDF.addImage(pageData, 'JPEG', 20, 0, imgWidth, imgHeight)
          } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, 'JPEG', 20, position, imgWidth, imgHeight)
            leftHeight -= pageHeight
            position -= 841.89
            if (leftHeight > 0) {
              PDF.addPage()
            }}
          }
          PDF.save(title + '.pdf')
        }
      )
    }
  }
}
</script>