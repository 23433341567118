// =========================================================================================
// Time        :2020/10/21 14:22:14
// Author      :周宇
// Email       :zhouyu674896488@gmail.com
// Version     :v1.0
// File        :index.js
// Desc        :
// 
// (C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
// ========================================================================================== -->


import axios from '../../axios.js'

export default axios
