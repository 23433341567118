// =========================================================================================
// Time        :2020/10/21 14:31:37
// Author      :周宇
// Email       :zhouyu674896488@gmail.com
// Version     :v1.0
// File        :getters.js
// Desc        :
// 
// (C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
// ========================================================================================== -->


const getters = {

  // COMPONENT
  // vx-autosuggest
  // starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  windowBreakPoint: state => {

    // This should be same as tailwind. So, it stays in sync with tailwind utility classes
    if (state.windowWidth >= 1200) return 'xl'
    else if (state.windowWidth >= 992) return 'lg'
    else if (state.windowWidth >= 768) return 'md'
    else if (state.windowWidth >= 576) return 'sm'
    else return 'xs'
  },

  scrollbarTag: state => {
    return state.is_touch_device ? 'div' : 'VuePerfectScrollbar'
  }
}

export default getters
