/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// =========================================================================================
// Time        :2020/10/21 14:34:44
// Author      :周宇
// Email       :zhouyu674896488@gmail.com
// Version     :v1.0
// File        :router.js
// Description  :Routes for vue-router. Lazy loading is enabled.
// Object Strucutre:
//                path => router path
//                name => router name
//                component(lazy loading) => component to load
//                meta : {
//                  rule => which user can have access (ACL)
//                  breadcrumb => Add breadcrumb to specific page
//                  pageTitle => Display title besides breadcrumb
//                }
// ----------------------------------------------------------------------------------------
// (C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
// ========================================================================================== -->


import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [

    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          redirect: '/Home'
        },
        {
          path: '/Home',
          name: 'home',
          component: () => import('./views/Home.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Application Routes
        // =============================================================================
        {
          path: '/psychology/user-list/:group_ptr_id',
          name: 'app-user-list',
          component: () => import('@/views/apps/user/user-list/UserList.vue'),
          meta: {
            breadcrumb: [
              { title: '主页', url: '/' },
              { title: '用户' },
              { title: '列表', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/mental/questionnaire',
          name: 'app-questionnaire-list',
          component: () => import('@/views/apps/mental/mentalList.vue'),
          meta: {
            breadcrumb: [
              { title: '主页', url: '/' },
              { title: '量表' },
              { title: '列表', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },
		{
          path: '/settings',
          name: 'settings',
          component: () => import('@/views/pages/config/settings.vue'),
          meta: {
            breadcrumb: [
              { title: '主页', url: '/' },
              { title: '配置信息', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/mental/questionnaire/:id',
          name: 'app-questionnaire-view',
          component: () => import('@/views/apps/mental/mentalView.vue'),
          meta: {
            breadcrumb: [
              { title: '主页', url: '/' },
              { title: '量表' },
              { title: '列表', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/psychology/user-view/:username',
          name: 'app-user-view',
          component: () => import('@/views/apps/user/UserView.vue'),
          meta: {
            breadcrumb: [
              { title: '主页', url: '/' },
              { title: '用户' },
              { title: '预览', active: true }
            ],
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/admin',
          name: 'admin',
          component: () => import('@/views/Admin.vue'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/callback',
          name: 'auth-callback',
          component: () => import('@/views/Callback.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/sign-in',
          name: 'page-login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/lock-screen',
          name: 'page-lock-screen',
          component: () => import('@/views/pages/LockScreen.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/comingsoon',
          name: 'page-coming-soon',
          component: () => import('@/views/pages/ComingSoon.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/maintenance',
          name: 'page-maintenance',
          component: () => import('@/views/pages/Maintenance.vue'),
          meta: {
            rule: 'editor'
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  document.querySelectorAll('body > div.vs-component').forEach(el => {
    el.remove()
  })
  document.querySelectorAll('body > div.vs-content-sidebar').forEach(el => {
    el.remove()
  })
})

router.beforeEach((to, from, next) => {

  // If auth required, check login. If login fails redirect to login page
  if (to.meta.authRequired) {
    if (!localStorage.getItem('accessToken')) {
      router.push({ path: '/sign-in', query: { to: to.path } })
    }
  }
  if (localStorage.getItem('accessToken') && to.path === '/sign-in') return next('/')
  return next()
})

const routerPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}

export default router
