// =========================================================================================
// Time        :2020/10/21 14:29:39
// Author      :周宇
// Email       :zhouyu674896488@gmail.com
// Version     :v1.0
// File        :moduleAuth.js
// Desc        :
// 
// (C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
// ========================================================================================== -->


import state from './moduleAuthState.js'
import mutations from './moduleAuthMutations.js'
import actions from './moduleAuthActions.js'
import getters from './moduleAuthGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
