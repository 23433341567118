<!-- =========================================================================================
Time        :2020/10/21 14:22:01
Author      :周宇
Email       :zhouyu674896488@gmail.com
Version     :v1.0
File        :VideoJSRecord.vue
Desc        :

(C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
========================================================================================== -->


<template>
  <div id="app">
    
    <video id="myVideo" class="video-js vjs-default-skin"></video>

    <div class="inputSelector" @change="this.changeAudioInput">
      <label :v-text="type">选择设备: </label>
      <select id="selector" ref="sel"></select>
    </div>
  </div>
    
</template>

<script>
/* eslint-disable */
import 'video.js/dist/video-js.css'
import 'videojs-record/dist/css/videojs.record.css'
import videojs from 'video.js'

import 'webrtc-adapter'
import RecordRTC from 'recordrtc'

// the following imports are only needed when you're recording
// audio-only using the videojs-wavesurfer plugin

import WaveSurfer from 'wavesurfer.js'
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js'
WaveSurfer.microphone = MicrophonePlugin

// register videojs-wavesurfer plugin
import videojs_wavesurfer_css from 'videojs-wavesurfer/dist/css/videojs.wavesurfer.css'
import Wavesurfer from 'videojs-wavesurfer/dist/videojs.wavesurfer.js'
import Record from 'videojs-record/dist/videojs.record.js'

import FFmpegjsEngine from 'videojs-record/dist/plugins/videojs.record.ffmpegjs.js';

var devices, deviceId

var inputSection = document.getElementsByClassName('inputSelector')[0]

export default {
  name: 'video-record',
  props: {
    file_type: String,
    default: () => "voice"
  },
  watch: {
    file_type(newVal, oldVal) {
      this.type = newVal
      newVal && this.updateType()
    } 
  },
  methods: {
    updateType () {
      this.options = {
        controls: true,
        autoplay: false,
        fluid: false,
        loop: false,
        width: 320,
        height: 240,
        bigPlayButton: false,
        controlBar: { volumePanel: false, pictureInPictureToggle: false, fullscreenToggle: false,},
        plugins: {  
          wavesurfer: { backend: 'WebAudio', waveColor: '#36393b', progressColor: 'black', debug: true, 
          cursorWidth: 1, displayMilliseconds: true, hideScrollbar: true, plugins: [ WaveSurfer.microphone.create({
          bufferSize: 4096, numberOfInputChannels: 1, numberOfOutputChannels: 1, constraints: { 
            video: this.type !== 'voice' ? true : false, audio: this.type === 'voice' ? true : false
          }})]},
          // configure videojs-record plugin
          record: {
              video: this.type !== 'voice',
              audio: this.type === 'voice', 
              maxLength: 600,
              debug: true,
              videoMimeType: 'video/webm',
              audioMimeType: 'audio/wav',
              audioRecorderType: RecordRTC.StereoAudioRecorder,
              
          }
        }
      },

      /* eslint-disable no-console */
      this.player = videojs('#myVideo', this.options, () => {
          // print version information at startup
        var msg = 'Using video.js ' + videojs.VERSION +
                  ' with videojs-record ' + videojs.getPluginVersion('record') +
                  ', videojs-wavesurfer ' + videojs.getPluginVersion('wavesurfer') +
                  ', wavesurfer.js ' + WaveSurfer.VERSION + ' and recordrtc ' +
                  RecordRTC.version;
        videojs.log(msg);
      })
      // device is ready
      this.player.one('deviceReady', () => {    
        this.player.record().enumerateDevices()
      })
      this.player.on('enumerateReady', () => {
        devices = this.player.record().devices
        // handle selection changes
        var inputSelector = document.getElementById('selector')
        inputSelector.addEventListener('change', this.changeAudioInput)

        // populate select options
        var deviceInfo, option, i
        for (i = 0; i !== devices.length; ++i) {
          deviceInfo = devices[i]
          option = document.createElement('option')
          option.value = deviceInfo.deviceId
          if (deviceInfo.kind === (this.type==='voice' ? "audioinput": 'videoinput')) {
            console.info(`Found ${this.type} input device: ${deviceInfo.label}`)
            option.text = deviceInfo.label || 'input device ' +
                (inputSelector.length + 1)
            inputSelector.appendChild(option)
          }
        }

        if (inputSelector.length == 0) {
            // no output devices found, disable select
          option = document.createElement('option')
          option.text = `No ${this.type} input devices found`
          option.value = undefined
          inputSelector.appendChild(option)
          inputSelector.disabled = true
          console.warn(option.text)
        } else {
          console.info(`Total ${this.type} input devices found: ${inputSelector.length}`)
        }

          // show input selector section
          // tinputSection.style.display = 'block'
      })

      // error handling
      this.player.on('enumerateError', () => {
        console.warn('enumerate error:', this.player.enumerateErrorCode)
      })
      // error handling
      this.player.on('error', (element, error) => {
        console.warn(error)
      })

      this.player.on('deviceError', () => {
        console.warn('device error:', this.player.deviceErrorCode)
      })

      // user clicked the record button and started recording
      this.player.on('startRecord', () => {
        console.log('started recording!')
      })

      // user completed recording and stream is available
      this.player.on('finishRecord', () => {
        // the blob object contains the recorded data that
        // can be downloaded by the user, stored on server etc.
        this.$emit('finished', true)
        console.log('finished recording', this.player.recordedData)
      })
      // converter started processing
      this.player.on('startConvert', () => {
        console.log('started converting!');
      });

      // converter completed and stream is available
      this.player.on('finishConvert', () => {
        // the convertedData object contains the recorded data that
        // can be downloaded by the user, stored on server etc.
        console.log('finished converting: ', this.player.convertedData);
      });
    },
    changeAudioInput (event) {
      var label = event.target.options[event.target.selectedIndex].text
      deviceId = event.target.value

      try {
        // change audio input device
        if (this.type === 'voice') this.player.record().setAudioInput(deviceId)
        else this.player.record().setVideoInput(deviceId)

        console.log(`Changed ${this.type} input to ${label} (deviceId: ${deviceId})`)
      } catch (error) {
        console.warn(error)

        // jump back to first output device in the list as it's the default
        event.target.selectedIndex = 0
      }
    }
  },
  data() {
    return {
      type: '',
      player: '',
      options: null,
    }
  },
  beforeDestroy() { 
    if (this.player) {
      this.player.dispose()
    }
  },
}
</script>
<style>
    #myVideo {
        width: 96%;
        margin: 2%;
        background-color: #95DDF5;
    }
    select {
      margin: 0 0 0.5em 0;
    }
    .inputSelector {
        margin: 2%;
    }
</style>