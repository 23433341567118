// =========================================================================================
// Time        :2020/10/21 14:30:50
// Author      :周宇
// Email       :zhouyu674896488@gmail.com
// Version     :v1.0
// File        :moduleMentalManagement.js
// Desc        :
// 
// (C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
// ========================================================================================== -->


import state from './moduleMentalManagementState.js'
import mutations from './moduleMentalManagementMutations.js'
import actions from './moduleMentalManagementActions.js'
import getters from './moduleMentalManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

