// =========================================================================================
// Time        :2020/10/21 14:34:27
// Author      :周宇
// Email       :zhouyu674896488@gmail.com
// Version     :v1.0
// File        :axios.js
// Desc        :
// 
// (C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
// ========================================================================================== -->


// axios
import axios from 'axios'

const baseURL = 'https://jy.saikelogic.com'

export default axios.create({
  baseURL,
  // You can add your headers here
})
