<!-- =========================================================================================
Time        :2020/10/21 14:32:07
Author      :周宇
Email       :zhouyu674896488@gmail.com
Version     :v1.0
File        :mentalView.vue
Desc        :

(C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
========================================================================================== -->


<template>
  <div id="page-mental-view">

    <vs-alert color="danger" title="User Not Found" :active.sync="mental_not_found">
      <span>Mental record with name: {{ $route.params.name }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{name:'app-questionnaire-list'}" class="text-inherit underline">All Templates</router-link>
      </span>
    </vs-alert>

    <div id="mental_data" v-if="mental_data">

      <vx-card class="mb-base" v-for="(item, index) in mental_data" :key="item.id" :title="item.title">
        <vs-list>

          <vs-list-header :title="item.description"></vs-list-header>
            <vs-list-item :title="item.option_a" :subtitle="null" v-if="item.option_a">
              <vs-radio :vs-name="item.title" :vs-value="item.option_a + ': ' + item.score_a + ': ' + item.feature_id" v-model="answerInfo[index]" @change="changeResult(item.option_a, item.id)"/>
            </vs-list-item>

            <vs-list-item :title="item.option_b" :subtitle="null" v-if="item.option_b">
              <vs-radio :vs-name="item.title" :vs-value="item.option_b + ': ' + item.score_b + ': ' + item.feature_id" v-model="answerInfo[index]" @change="changeResult(item.option_b, item.id)"/>
            </vs-list-item>

            <vs-list-item :title="item.option_c" :subtitle="null" v-if="item.option_c">
              <vs-radio :vs-name="item.title" :vs-value="item.option_e + ': ' + item.score_c + ': ' + item.feature_id" v-model="answerInfo[index]" @change="changeResult(item.option_c, item.id)"/>
            </vs-list-item>

            <vs-list-item :title="item.option_d" :subtitle="null" v-if="item.option_d">
              <vs-radio :vs-name="item.title" :vs-value="item.option_d + ': ' + item.score_d + ': ' + item.feature_id" v-model="answerInfo[index]" @change="changeResult(item.option_d, item.id)"/>
            </vs-list-item>

            <vs-list-item :title="item.option_e" :subtitle="null" v-if="item.option_e">
              <vs-radio :vs-name="item.title" :vs-value="item.option_e + ': ' + item.score_e + ': ' + item.feature_id" v-model="answerInfo[index]" @change="changeResult(item.option_e, item.id)"/>
            </vs-list-item>

            <vs-list-item :title="item.option_f" :subtitle="null" v-if="item.option_f">
              <vs-radio :vs-name="item.title" :vs-value="item.option_f + ': ' + item.score_f + ': ' + item.feature_id" v-model="answerInfo[index]" @change="changeResult(item.option_f, item.id)"/>
            </vs-list-item>
        </vs-list>
      </vx-card>
        <center><vs-button @click="submitMental">提交</vs-button></center>
    </div>
  </div>
</template>

<script>
import moduleMentalManagement from '@/store/mental-management/moduleMentalManagement.js'

export default {
  data () {
    return {
      mental_data     : null,
      mental_not_found: false,
      answerInfo      : [],
      answer_history  : {},
      answer          :false,
      result          :false
    }
  },
  methods: {
    changeResult (question, index) {
      if (this.answer_history[index])
        this.answer_history[index].push(question)
      else this.answer_history[index] = [question]
    },
    sumScore (array) {
      let sum_score = {}
      let answer    = []
      let feature   = []

      for (let i in array) {
        answer.push(array[i].split(': ')[0])
        feature.push(array[i].split(': ')[2])

        if (feature[i] in sum_score) 
          sum_score[feature[i]] += parseFloat(array[i].split(': ')[1])
        else sum_score[feature[i]] = parseFloat(array[i].split(': ')[1])
      }
      return {sum: Object.values(sum_score), answer: answer, feature_id: feature}
    },
    submitMental () {
      this.person_id     = this.$store.state.AppActiveUser.id
      const timestramp   = Date.parse( new Date() )
      this.obj           = this.sumScore(this.answerInfo)
      const mentalAnswer = []
      const mentalResult = []
      let item_id        = Object.keys(this.answer_history)
      let answer_history = Object.values(this.answer_history)
      let answer         = this.obj.answer
      let tag            = timestramp
      let template_id    = this.$route.params.id
      let feature_id     = this.obj.feature_id
      let sumScore       = this.obj.sum
      
      for (let i in item_id) {
        mentalAnswer.push({
          item_id       : item_id[i],
          answer        : answer[i],
          answer_history: answer_history[i].join(','),
          person_id     : this.person_id,
          tag           : tag
        })
      }
      for  (let i in feature_id) {
        mentalResult.push({
          person_id   :      this.person_id,
          template_id :      template_id,
          feature_id  :      feature_id[i],
          score       :      sumScore[i],
          tag         :      tag
        })
      }

      this.$store.dispatch('mentalManagement/addMentalResult', mentalResult)
        .then(res => { this.$vs.notify({color: 'success', title: '成功', text: '提交最终得分成功'}) })

      this.$store.dispatch('mentalManagement/addMentalAnswer', mentalAnswer)
        .then(res => { this.$vs.notify({color: 'success', title: '成功', text: '提交历史结果成功'}) })
    }
  },
  created () {
    const id = this.$route.params.id
    if (!moduleMentalManagement.isRegistered) {
      this.$store.registerModule('mentalManagement', moduleMentalManagement)
      moduleMentalManagement.isRegistered = true
    }
    this.$store.dispatch('mentalManagement/fetchMental', id)
      .then(res => { this.mental_data = res.data })
      .catch(err => {
        if (err.response.status === 404) {
          this.mental_not_found = true
          return
        }
        console.error(err) 
      })
  }
}
</script>

<style lang="scss">

#avatar-col {
  width: 10rem;
}

#page-mental-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width:370px) {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width:1201px) and (max-width:1211px),
only screen and (min-width:636px) and (max-width:991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }
}

</style>
