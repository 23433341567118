<!-- =========================================================================================
Time        :2020/10/21 14:32:27
Author      :周宇
Email       :zhouyu674896488@gmail.com
Version     :v1.0
File        :CellRendererSex.vue
Desc        :

(C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
========================================================================================== -->


<template>
  <span> {{ text }} </span>
</template>

<script>
export default {
  name: 'CellRendererSex',
  computed: {
    text () {
      if (this.params.value === 0) return '女'
      else if (this.params.value === 1) return '男'
      else return '不详'
    }
  }
}
</script>
