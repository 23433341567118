<!-- =========================================================================================
Time        :2020/10/21 14:32:33
Author      :周宇
Email       :zhouyu674896488@gmail.com
Version     :v1.0
File        :CellRendererVerified.vue
Desc        :

(C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
========================================================================================== -->


<template>
    <feather-icon icon="CircleIcon" :svgClasses="['fill-current h-5 w-5', textColor]"/>
</template>

<script>
export default {
  name: 'CellRendererVerified',
  computed: {
    textColor () {
      return this.params.value ? 'text-primary' : 'text-grey'
    }
  }
}
</script>
<style lang="scss">
feather-icon {
 left: 5px
}
</style>
