<!-- =========================================================================================
Time        :2020/10/21 14:33:28
Author      :周宇
Email       :zhouyu674896488@gmail.com
Version     :v1.0
File        :Maintenance.vue
Desc        :

(C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
========================================================================================== -->


<template>
    <div class="h-screen flex w-full bg-img">
        <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
            <img src="@/assets/images/pages/maintenance-2.png" alt="graphic-maintenance" class="mx-auto mb-4">
            <h1 class="sm:mx-0 mx-4 mb-6 text-5xl d-theme-heading-color">正在维修！</h1>
            <p class="sm:mx-0 mx-4 mb-6 d-theme-text-inverse">该服务后端正在维护更新，请稍后访问。</p>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      value1: ''
    }
  }
}
</script>
