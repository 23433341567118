// =========================================================================================
// Time        :2020/10/21 14:31:18
// Author      :周宇
// Email       :zhouyu674896488@gmail.com
// Version     :v1.0
// File        :moduleUserManagementActions.js
// Desc        :
// 
// (C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
// ========================================================================================== -->


import axios from '@/axios.js'

export default {
  fetchUsers ({ commit }, group_ptr_id) {

    return new Promise((resolve, reject) => {
      axios.get('/rest/v1/Users', { 
        params: {group_ptr_id: group_ptr_id}
      })
        .then((response) => {
          commit('SET_USERS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchUser (context, username) {
    return new Promise((resolve, reject) => {
      axios.get(`/rest/v1/Users/${username}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchGroups ({ commit }, user) {
    return new Promise((resolve, reject) => {
      axios.get('/rest/v1/Groups/', {
        params: {user: user}
      })
        .then((response) => {
          commit('SET_GROUPS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
