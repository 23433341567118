/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// =========================================================================================
// Time        :2020/10/21 14:23:33
// Author      :周宇
// Email       :zhouyu674896488@gmail.com
// Version     :v1.0
// File        :navMenuItems.js
// Description : Sidebar Items list. Add / Remove menu items from here.
// Strucutre:
//      url     => router path
//      name    => name to display in sidebar
//      slug    => router path name
//      icon    => Feather Icon component/icon name
//      tag     => text to display on badge
//      tagColor  => class to apply on badge element
//      submenu   => submenu of current item (current item will become dropdown )
//            NOTE: Submenu don't have any icon(you can add icon if u want to display)
//      isDisabled  => disable sidebar item/group
// 
// (C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
// ========================================================================================== -->


export default [
  {
    url: '/Home',
    name: '主页',
    icon: 'HomeIcon'
  },
  {
    header: '行为分析',
    icon: 'PackageIcon',
    items: [
      {
        url: null,
        name: '组织结构',
        icon: 'UsersIcon',
        submenu: []
      }
    ]
  },
  {
    header: '心理量表',
    items: [
      {
        url: '/mental/questionnaire',
        name: '量表问卷',
        icon: 'FileIcon'
      }
    ]
  },
  {
    header: '其他',
    items: [
      {
        url: null,
        name: '用户手册',
        icon: 'BookOpenIcon'
      },
      {
        url: '/settings',
        name: '用户设置',
        icon: 'SettingsIcon'
      },
      {
        url: '/admin',
        name: '更多支持',
        icon: 'MoreHorizontalIcon'
      }
    ]
  }
]

