// =========================================================================================
// Time        :2020/10/21 14:29:43
// Author      :周宇
// Email       :zhouyu674896488@gmail.com
// Version     :v1.0
// File        :moduleAuthActions.js
// Desc        :
// 
// (C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
// ========================================================================================== -->


import jwt from '../../http/requests/auth/jwt/index.js'


export default {

  // JWT
  loginJWT ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      jwt.login(payload.userDetails.email, payload.userDetails.password)
        .then(response => {
          // If there's user data in response
          if (response.data.userData) {
            // Set accessToken
            localStorage.setItem('accessToken', response.data.access)
            localStorage.setItem('refreshToken', response.data.refresh)

            // Update user details
            commit('UPDATE_USER_INFO', response.data.userData, {root: true})

            // Set bearer token in axios
            commit('SET_BEARER', response.data.access)

            resolve(response)
          }
        }).catch((err) => {
          reject({message: err.response.data.message[0]})
        })
    })
  },
  fetchAccessToken () {
    return new Promise((resolve) => {
      jwt.refreshToken().then(response => { resolve(response) })
    })
  }
}
