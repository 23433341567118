// =========================================================================================
// Time        :2020/10/21 14:20:38
// Author      :周宇
// Email       :zhouyu674896488@gmail.com
// Version     :v1.0
// File        :acl.js
// Desc        :
// 
// (C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
// ========================================================================================== -->


import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from '@/router'

Vue.use(AclInstaller)

let initialRole = 'admin'

const userInfo = JSON.parse(localStorage.getItem('userInfo'))
if (userInfo) {
  if (userInfo.is_superuser) initialRole = 'admin'
  else initialRole = 'editor'
} 

export default new AclCreate({
  initial  : initialRole,
  notfound : '/pages/not-authorized',
  router,
  acceptLocalRules : true,
  globalRules: {
    admin  : new AclRule('admin').generate(),
    editor : new AclRule('editor').or('admin').generate()
  }
})
