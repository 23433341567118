// =========================================================================================
// Time        :2020/10/21 14:30:54
// Author      :周宇
// Email       :zhouyu674896488@gmail.com
// Version     :v1.0
// File        :moduleMentalManagementActions.js
// Desc        :
// 
// (C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
// ========================================================================================== -->


import axios from '@/axios.js'

export default {
  fetchMentalItems ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/rest/v1/MentalTemplate/')
        .then((response) => {
          commit('SET_MENTAlS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchMental (context, id) {
    return new Promise((resolve, reject) => {
      axios.get('/rest/v1/MentalItem/', {
        params: {id: id}
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  addMentalResult ({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post('/rest/v1/MentalResult/', item)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  addMentalAnswer ({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post('/rest/v1/MentalAnswer/', item)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
