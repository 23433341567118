<!-- =========================================================================================
Time        :2020/10/21 14:32:44
Author      :周宇
Email       :zhouyu674896488@gmail.com
Version     :v1.0
File        :DataViewSidebar.vue
Desc        :

(C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
========================================================================================== -->


<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  > 
    
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>添加 档案 </h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <video-record ref='child' v-bind:file_type='data.file_type' v-on:finished="finished"/>

    <component
      class="scroll-area--data-list-add-new"
      :is       ="scrollbarTag"
      :settings ="settings"
      :key      ="$vs.rtl"
    >
    </component>
    
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">提交</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">取消</vs-button>
    </div>

  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  props: {
    isSidebarActive: {
      type    : Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      valid: false,
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed        : 0.6
      },
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      },
    },
    isFormValid () {
      return this.valid ? true : false
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    finished (finished) {
      this.valid = finished
    },
    callback (data) {
      console.debug(data)
    },
    submitData () {
      this.$parent.getFile(this.$refs.child.$emit('finishRecord').player.recordedData, this.data.file_type)
      this.$emit('closeSidebar')
    }
  }
}
</script>

<style lang='scss' scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 20%;
    max-width: 90vw;
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem)
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
#myVideo {
    background-color: #95DDF5;
}
select {
  margin: 0 0 0.5em 0;
}
.inputSelector {
    margin-top: 30px;
}
</style>
