<!-- =========================================================================================
Time        :2020/10/22 17:09:11
Author      :周宇
Email       :zhouyu674896488@gmail.com
Version     :v1.0
File        :AudioWaveSufer.vue
Desc        :

(C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
========================================================================================== -->


<template>
  <div class="mixin-components-container">
		<vx-card class="box-card" style="text-align:left">
			<div id="waveform" ref="waveform"></div>
			<div id="wave-timeline" ref="wave_timeline"></div>
			<div id="wave-spectrogram" ref="wave_spectrogram"></div>
			<center>
				<vs-button color="primary" type="filled" @click="plays"> 播放/暂停</vs-button>
			</center>
		</vx-card>
  </div>
</template>
<script>
import WaveSurfer from 'wavesurfer.js'
import Minimap from 'wavesurfer.js/dist/plugin/wavesurfer.minimap.js'
import Timeline from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.js' //插件依赖
import Spectrogram from 'wavesurfer.js/dist/plugin/wavesurfer.spectrogram.js' //插件依赖
import Cursor from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.js' //插件依赖
export default {
	name: "audio-wave-surfer",
	props: {
		file_name: String,
	},
	watch: {
    file_name (newVal, oldVal) {
      this.file_name = newVal
      newVal && this.player()
    } 
  },
  // components: { MyWaveSurfer },
  data() {
    return {
      wavesurfer: null,
    };
	},
	beforeDestroy() { 
    if (this.wavesurfer) {
      this.wavesurfer.destroy()
    }
  },
	mounted () {
		this.player()
	},
  methods: {
    // 播放时暂停，播放时暂停
    plays() {
      this.wavesurfer.playPause()
		},
		player () {
			if (this.wavesurfer) {
				this.wavesurfer.destroy()
			}
      this.wavesurfer = WaveSurfer.create({
        // 应该在其中绘制波形的CSS选择器或HTML元素。这是唯一必需的参数。
        container: this.$refs.waveform,
        waveColor: 'violet',
        // 光标后面的波形部分的填充色。当progressColor和waveColor相同时，完全不渲染进度波
        progressColor: 'purple',
        backend: 'MediaElement',
        // 音频播放时间轴
        mediaControls: false,
        // 隐藏滚动条
        hideScrollbar: true,
        // 播放音频的速度
        audioRate: '1',
        // 插件：此教程配置了光标插件和时间轴插件
        plugins: [
          // Minimap
          Minimap.create({
            waveColor: 'grey',
            progressColor: 'black',
            height: 30
		  }),
		  Timeline.create({
			container: this.$refs.wave_timeline
		  }),
		  // Spectrogram.create({
		  //   wavesurfer: this.wavesurfer,
		  //   container: this.$refs.wave_spectrogram,
		  //   labels: true
		  // }),
		  Cursor.create({
			showTime: true,
			opacity: 1,
			customShowTimeStyle: {
			  'background-color': '#000',
			  color: '#fff',
			  padding: '2px',
			  'font-size': '10px'
			}
		  })
        ]
      });
      this.wavesurfer.on('error', function(e) {
        console.warn(e);
      });
      this.wavesurfer.load(this.file_name);
  	},
  }
}
</script>
<style scoped>
.mixin-components-container {
	background-color: #f0f2f5;
  padding: 30px;
	min-height: calc(100vh - 84px);
}
.mixin-components-container div *{
	margin: 20px;
}
</style>

