// =========================================================================================
// Time        :2020/10/21 14:30:04
// Author      :周宇
// Email       :zhouyu674896488@gmail.com
// Version     :v1.0
// File        :moduleConfigActions.js
// Desc        :
// 
// (C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
// ========================================================================================== -->


import axios from '@/axios.js'

export default {
  fetchItem (context) {
    return new Promise((resolve, reject) => {
	  axios.get('/rest/v1/Config/')
	    .then((response) => {
		  resolve(response)
	    })
		.catch((error) => { reject(error) })
	})
  },
  updateItem ({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.put(`/rest/v1/Config/${item.id}/`, item)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
