// =========================================================================================
// Time        :2020/10/21 14:30:00
// Author      :周宇
// Email       :zhouyu674896488@gmail.com
// Version     :v1.0
// File        :moduleConfig.js
// Desc        :
// 
// (C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
// ========================================================================================== -->


import state from './moduleConfigState.js'
import mutations from './moduleConfigMutations.js'
import actions from './moduleConfigActions.js'
import getters from './moduleConfigGetters.js'

export default {
  isRegistered: true,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
