<!-- =========================================================================================
Time        :2020/10/21 14:23:08
Author      :周宇
Email       :zhouyu674896488@gmail.com
Version     :v1.0
File        :ProfileDropDown.vue
Desc        :

(C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
========================================================================================== -->


<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.username">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.username }}</p>
      <small>{{ activeUserInfo.numbering }}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="con-img ml-3">
        <img v-if="activeUserInfo.avatar" key="onlineImg" :src="avatar + activeUserInfo.avatar" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>

export default {
  data () {
    return {
      avatar: 'http://127.0.0.1:8000/media/'
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    logout () {

      // If JWT login
      if (localStorage.getItem('accessToken')) {
        localStorage.removeItem('accessToken')
        this.$router.push('/sign-in').catch(() => {})
      }

      // Change role on logout. Same value as initialRole of acj.js
      this.$acl.change('admin')
      localStorage.removeItem('userInfo')

      // This is just for demo Purpose. If user clicks on logout -> redirect
      this.$router.push('/sign-in?to=/Home').catch(() => {})
    }
  }
}
</script>
