<!-- =========================================================================================
Time        :2020/10/21 14:32:48
Author      :周宇
Email       :zhouyu674896488@gmail.com
Version     :v1.0
File        :UserTabFace.vue
Desc        :

(C)Copyright 2019-2020, 斑图信息科技（青岛）有限公司
========================================================================================== -->


<template>
  <div id="data-list-list-view" class="data-list-container">
    <div class="flex flex-wrap items-center justify-center" v-if="lastestBrief_exp">
      <div class='w-full lg:w-1/4 h-64 mb-8' v-for="(item, index) in lastestPie" :key="index">
        <e-charts autoresize :options="lastestPie[index]" auto-resize class='h-48'/>
        <center>{{ lastestBrief_exp[index] }}</center>
      </div>
    </div>


    <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />

    <vs-table ref="table" multiple v-model="selected" pagination :max-items="itemsPerPage" search :data="products">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- ADD NEW -->
          <div class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData('face')">
              <feather-icon icon="VideoIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">录制</span>
          </div>
          <div class="p-3 mb-4 mr-4 rounded-lg flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary">
            <feather-icon icon="UploadIcon" svgClasses="h-4 w-4" />
            <a href="javascript:;" class="a-upload mr10 ml-2 text-base text-primary">
              <input type="file" accept="video/*" name="myFiles" id="upload" @change="handleFileSelect">上传
            </a>
          </div>
        </div>
      </div>

      <template slot="thead">
        <vs-th sort-key="ID">ID</vs-th>
        <vs-th sort-key="time">上传日期</vs-th>
        <vs-th sort-key="name">文件名</vs-th>
        <vs-th sort-key="type">文件类型</vs-th>
        <vs-th sort-key="status">文件状态</vs-th>
        <vs-th>Action</vs-th>
      </template>

        <template slot-scope="{data}">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <p class="product-id font-medium truncate">{{ indextr+1 }}</p>
              </vs-td>
              <vs-td>
                <p class="product-id font-medium truncate">{{ tr.create_time }}</p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.file_name }}</p>
              </vs-td>
              <vs-td>
                <p class="product-type font-medium truncate">{{ getTypeText(tr.file_type) }}</p>
              </vs-td>

              <vs-td>
                <vs-chip :color="getOrderStatusColor(tr.status)" class="product-status">{{ getStatusText(tr.status) }}</vs-chip>
              </vs-td>

              <vs-td>
                <feather-icon icon="EyeIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" v-if="tr.status==2" @click="popupActive(tr)" />

                <vs-popup fullscreen title="" :active.sync="popup">

                  <vs-card v-if="view_data">

                    <div class="vx-col lg:w-full">
                      <vx-card title="面部视频" class="mb-base">
                        <center>
                          <video class="vx-col lg:w-1/2" controls :src="view_data.result_file"/>
                        </center>
                      </vx-card>
                    </div>

                    <div class="vx-row" id="download">

                      <div class="vx-col lg:w-full">
                        <vx-card title="面部视频分析" class="mb-base">
                          <e-charts autoresize :options="line" auto-resize />
                        </vx-card>
                      </div>

                      <div class="vx-col lg:w-full" v-for="(item, index) in pie" :key="index">
                        <vx-card :title="item.name" class="mb-base">
                          <e-charts autoresize :options="item" auto-resize />
                        </vx-card>
                      </div>
                      <div class="vx-col lg:w-full" v-if="radar.flag">
                        <vx-card title="大五人格分析" class="mb-base">
                          <e-charts autoresize :options="radar" auto-resize />
                        </vx-card>
                      </div>
                      <div style="display: flex;">
                         <div>
                           <label>平均心率</label><span>77</span>
                         </div>
                        <div style="margin-left: 100px;">
                           <label>呼吸频率</label><span>2.3</span>
                         </div>
                        <div style="margin-left: 100px;">
                           <label>心率异变</label><span>0.25</span>
                         </div>
                      </div>
                    </div>
                    <div style="width:100%;margin-top: 20px;">
                        <img src="@/assets/images/pages/ssccx.png" style="width:100%;"></img>
                    </div>
                    <center><vs-button class="mb-base mr-3" type="border" icon-pack="feather" icon="icon icon-download" @click="Export()">打印报告</vs-button></center>
                  </vs-card>
                </vs-popup>

              </vs-td>
            </vs-tr>
          </tbody>
        </template>
    </vs-table>
  </div>
</template>

<script>

import DataViewSidebar    from './DataViewSidebar.vue'
import moduleFileList     from '@/store/data-list/moduleDataList.js'
import ECharts            from 'vue-echarts/components/ECharts'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/radar'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/visualMap'
import 'echarts/lib/component/title'
import 'echarts/lib/component/toolbox'
import 'echarts/lib/component/graphic'

export default {
  components: {
    DataViewSidebar,
    ECharts
  },
  props: ['data'],

  data () {
    return {
      personalities             : ['exoteric', 'pious', 'extroversion', 'delightful', 'deliration'],
      lastestBrief_exp          : [],
      line                      : {
        title: { show: false, text: this.data.username + '_分析曲线'},
        legend: { show: true, data: this.dataList },
        tooltip: { trigger: 'axis' },
        toolbox: { show: true, feature: { saveAsImage: { show: true }}},
        xAxis: { data: [] },
        yAxis: { splitLine: { show: true }},
        series: []
      },
      pie                       : [],
      lastestPie                : [],
      radar                     : {},
      popup                     : false,
      file_type                 : 'face',
      view_data                 : null,
      selected                  : [],
      itemsPerPage              : 4,
      files                     : [],
      isMounted                 : false,
      uploadActive              : false,
      viewActive                : false,
      username                  : null,
      // Data Sidebar
      addNewDataSidebar         : false,
      sidebarData               : {}
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    products () {
      return this.$store.state.dataList.products
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.products.length
    }
  },
  methods: {
    Export () {
      this.getPdf('download', this.data.username)
    },
    getData (value) {
      return [{
        value: value,
        itemStyle: { normal: { color: '#fb358a', shadowBlur: 10, shadowColor: '#fb358a' }}},
        { value: 100 - value, itemStyle: { normal: { color: 'transparent' }}
      }]
    },
    linesCharts (linesKey, linesVal) {

      // charts线图
      for (let i in linesKey) {
        this.line.series.push({
          name: linesKey[i],
          type: 'line',
          showSymbol: true,
          data: linesVal[i],
        })
      }
    },
    pieCharts (pieKey, radarKey, featureKey, featureVal) {
      let pieVal = []
      let radarVal = []
      const Eng2ChiMap = {
        'extroversion'      : '外倾',
        'delightful'        : '宜人',
        'pious'             : '尽责',
        'deliration'        : '神经',
        'exoteric'          : '开放',
        'depressed'         : '活力',  //PHQ9（抑郁）
        'dysphoric'         : '焦虑程度', //GAD7（焦虑）
        'pressure'          : '压力',
        'risk'              : '风险',
        'sensation'         : '感觉',
        'cognition'         : '认知',
        'selfrespect'       : '身体平衡感', //自尊
        'tai'               : 'tai',
        'cesd'              : 'cesd（抑郁）',
        'rational'          : '理性',
        'intuition'         : '直觉',
        '16'                : '16',
        '17'                : '17',
        '18'                : '18',
        'risk orientation'  : '攻击性', // 风险需求
        'sensory needs'     : '感觉需求',
      }
      for (let i = 0; i < featureKey.length; i++) {
        for (let j = 0; j < pieKey.length; j++){
          if (pieKey[j] ===featureKey[i]) {
            pieVal.push(featureVal[i])
          }
        }
      }
      // 查询特征指标对应分值描述
      this.$store.dispatch('dataList/fetchFeature', featureKey)
      .then(res => {
        // charts进度图
        for (let i = 0; i < pieKey.length; i++) {
          this.pie[i] = {
            name: Eng2ChiMap[pieKey[i]],
            title: { text: Number(pieVal[i]).toFixed(2), x: 'center', y: 'center', textStyle: { color: '#98a0c4', fontWeight: 'bolder', fontSize: 32 }},
            toolbox: { show: true, feature: { saveAsImage: { show: true , name: this.data.username + '_' + Eng2ChiMap[pieKey[i]]}}},
            series: [
              { type: 'pie', radius: ['39%', '49%'], silent: true, label: { normal: { show: false }}, animation: false,
              data: [{ value: pieVal[i], itemStyle: { normal: { color: '#333645', shadowBlur: 10, shadowColor: '#1b1e25' }}}]},
              { name: 'main', type: 'pie', radius: ['50%', '51%'], label: { normal: { show: false }}, data: this.getData(pieVal[i]),
              animationEasingUpdate: 'cubicInOut', animationDurationUpdate: 1000 }],
            graphic: [
              { type: 'group', left: 'center', bottom: 50, children: [{ type: 'rect', z: 100, left: 'center', top: 'middle', style: { fill: '#fff', stroke: '#555', lineWidth: 2, shadowBlur: 8, shadowOffsetX: 3, shadowOffsetY: 3, shadowColor: 'rgb(0, 0, 0, 0.3)' }}, {
                type: 'text', z: 100, left: 'center', top: 'middle', style: { fill: '#333', text: [], font: '11px Microsoft YaHei' }}]}
            ]
          }
          // 进度图补充说明描述
          pieVal[i] = Number(pieVal[i])
          for (let j = 0; j < res.data.length; j++) {
            if (res.data[j].name === pieKey[i]) {
              let comment = JSON.parse(res.data[j].comment)
              for (let k in comment){
                if (pieVal[i] >= comment[k].start && pieVal[i] < comment[k].end ) {
                  const content = comment[k].explaination.replace("抑郁", "活力").replace("自尊", "身体平衡感").replace("风险倾向", "攻击性")
                  this.pie[i].graphic[0].children[1].style.text = content
                }
              }
            }
          }
        }
        // 五大人格雷达图

        for (let i = 0; i < featureKey.length; i++) {
          for (let j = 0; j < radarKey.length; j++){
            if (radarKey[j] === featureKey[i]) {
              radarVal.push(featureVal[i])
            }
          }
        }
        let indicator = []
        for (let i = 0; i < radarKey.length; i++) {
          indicator.push({ name: Eng2ChiMap[radarKey[i]], max: 100, num: 100, value: radarVal[i]})
        }

        this.radar = {
          flag: radarKey.length === 5 ? true : false,
          color: ['#00e4ff', '#8E42FF', '#FF6B6B', '#FF9B42', '#F0D418'],
          tooltip: {},
          toolbox: { show: true, feature: {saveAsImage: { show: true , name: this.data.username + '_五大人格分析'}}},
          radar: {
            zlevel: 11, shape: 'circle', scale: true, indicator: indicator, center: ['50%', '35%'], radius: '30%', startAngle: 30,
            name: { show: true,color: 'transparent' }, axisLine: {
              show: true, lineStyle: { color: 'rgba(31,126,255, .5)' }}, splitLine: { lineStyle: { color: 'rgba(31,126,255, .5)' }},
              splitArea: { areaStyle: { color: 'rgba(32,126,255, 0.1)' }}},
          polar: { radius: '40%' },
          angleAxis: {
            zlevel: 0, min: 0, max: 360, interval: 5, clockwise: false, axisTick: { show: false }, axisLable: { show: false },
            axisLine: { show: false }, splitLine: { show : false }},
          radiusAxis: {
            zlevel: 0, min: 0, max: 100, axisLable: { textStyle: { color: 'rgba(29.208.240, .5)' }},axisLine: { show: false },
            splitLine: { show: false }, splitArea: { show: false }},
          series: [{
            type: 'radar', zlevel: 2, z: 4,
            data: [
              { value: radarVal, name: '分值', max: this.max, areaStyle: { normal: { color: { type: 'radial', x: 0.5, y: 0.5, r: 0.5, colorStop: [{ offset: 0, color: 'rgba(32, 126, 255, 0.5)' }, { offset: 1, color: 'rgba(32,126, 255, 0.1)' }], global: false }}}}]},
              { type: 'pie', startAngle: 0, data: indicator.map(item => { return { name: item.name, value: item.value}}).reverse(), center: ['50%', '35%'], radius: ['35%', '45%'], zlevel: -1, name: '', itemStyle: { normal: {} }, label: { normal: { position: 'top',fontSize: '14', color: '#98a0c4' }}}],
          graphic: [
              { type: 'group', left: 'center', bottom: 0  , children: [{ type: 'rect', z: 100, left: 'center', top: 'middle', style: { fill: '#fff', stroke: '#555', lineWidth: 2, shadowBlur: 8, shadowOffsetX: 3, shadowOffsetY: 3, shadowColor: 'rgb(0, 0, 0, 0.3)' }},
              { type: 'text', z: 100, left: 'center', top: 'middle', style: { fill: '#333', text: '', textFont: '11px verdana' }}]}
            ]
        }
        for (let i = 0; i < radarKey.length; i++) {
          // 进度图补充说明描述
          radarVal[i] = Number(radarVal[i])
          for (let j = 0; j < res.data.length; j++) {
            if (res.data[j].name === radarKey[i]) {
              let personality_comment = JSON.parse(res.data[j].comment)
              for (let k in personality_comment){
                if (radarVal[i] >= personality_comment[k].start && radarVal[i] < personality_comment[k].end ) {
                  this.radar.graphic[0].children[1].style.text += Eng2ChiMap[radarKey[i]] + '-----' + personality_comment[k].explaination + '\n\n'
                }
              }
            }
          }
        }
      })
      .catch(err => {
        console.error(err)
      })
    },
    popupActive (data) {
      this.popup = true
      this.view_data = data
      // 指标图
      let feature = JSON.parse(data.result).feature
      // 线图
      let sampling = JSON.parse(data.result).sampling
      // 指标图名称
      let featureKey = Object.keys(feature)
      // 指标图值
      let featureVal = Object.values(feature)
      // 线图名称
      let linesKey = Object.keys(sampling)
      // 线图值
      let linesVal = Object.values(sampling)

      // 过滤指标图中五大人格
      let pieKey = featureKey.filter((v) => { return this.personalities.indexOf(v) == -1 })
      let radarKey = featureKey.filter((v) => { return this.personalities.indexOf(v) > -1 })

      this.pieCharts(pieKey, radarKey, featureKey, featureVal)

      this.linesCharts(linesKey, linesVal)
    },
    renderSize (value) {
      if (null === value || value === '') {
        return '0 Bytes'
      }
      const unitArr = new Array('Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB')
      let index = 0
      const srcsize = parseFloat(value)
      index = Math.floor(Math.log(srcsize) / Math.log(1024))
      let size = srcsize / Math.pow(1024, index)
      size = size.toFixed(2)  //保留的小数位数
      return size + unitArr[index]
    },
    upload (fileObj) {
      this.$store.dispatch('dataList/addItem', fileObj)
        .then(() => {
          this.$vs.notify({color: 'success', title: '成功', text: '上传成功'})
        })
    },

    getFile (target, flag) {
      if (!target) return
      const self = this
      const formData = new FormData()

      formData.append('file_name', target.name)
      formData.append('file_path', target)
      formData.append('file_size', this.renderSize(target.size))
      formData.append('file_type', flag)
      formData.append('person_id', this.data.id)
      formData.append('status', 0)
      self.upload(formData)
      self.$forceUpdate()
    },
    handleFileSelect (e) {
      // 加入this.files
      this.files.push({})
      this.$nextTick(() => {
        this.getFile(e.target.files[0], this.file_type)
      })
    },
    addNewData (file_type) {
      this.sidebarData = {file_type}
      this.toggleDataSidebar(true)
    },
    getOrderStatusColor (status) {
      if (status === 1)   return 'warning'
      if (status === 2) return 'success'
      if (status === 3)  return 'danger'
      return 'primary'
    },
    getStatusText (status) {
      if (status === 1)   return '处理中'
      if (status === 2) return '已完成'
      if (status === 3)  return '异常'
      return '未处理'
    },
    getTypeText (type) {
      return '面部'
    },
    toggleDataSidebar (val = false) {
      this.addNewDataSidebar = val
    }
  },
  created () {
    if (!moduleFileList.isRegistered) {
      this.$store.registerModule('dataList', moduleFileList)
      moduleFileList.isRegistered = true
    }
    this.username = this.$route.params.username

    this.$store.dispatch('dataList/fetchFileListItems', {username: this.username, flag: this.file_type})
    .then(res=> {
      for (let i = res.data.length - 1; i > -1; i--) {
        if (res.data[i].status === 2) {
          // 指标图
          this.lastestFeature = JSON.parse(res.data[i].result).feature
          // 指标图名称
          this.lastestFeatureKey = Object.keys(this.lastestFeature)
          // 指标图值
          this.lastestFeatureVal = Object.values(this.lastestFeature)
          // 过滤指标图中五大人格
          this.lastestPieVal = []

          this.lastestPieKey = this.lastestFeatureKey.filter((v) => { return this.personalities.indexOf(v) == -1 })

          for (let i = 0; i < this.lastestFeatureKey.length; i++) {
            for (let j = 0; j < this.lastestPieKey.length; j++){
              if (this.lastestPieKey[j] === this.lastestFeatureKey[i]) {
                this.lastestPieVal.push(this.lastestFeatureVal[i])
              }
            }
          }
          this.$store.dispatch('dataList/fetchFeature', this.lastestFeatureKey)
          .then(res => {
            // charts进度图
            for (let i = 0; i < this.lastestPieKey.length; i++) {
              this.lastestPie[i] = {
                title: { text: Number(this.lastestPieVal[i]).toFixed(2), x: 'center', y: 'center', textStyle: { color: '#98a0c4', fontWeight: 'bolder', fontSize: 18 }},
                series: [
                  { type: 'pie', radius: ['39%', '49%'], silent: true, label: { normal: { show: false }}, animation: false,
                  data: [{ value: this.lastestPieVal[i], itemStyle: { normal: { color: '#333645', shadowBlur: 10, shadowColor: '#1b1e25' }}}]},
                  { name: 'main', type: 'pie', radius: ['50%', '51%'], label: { normal: { show: false }}, data: this.getData(this.lastestPieVal[i]),
                  animationEasingUpdate: 'cubicInOut', animationDurationUpdate: 1000 }]
              }
              // 进度图补充说明描述
              this.lastestPieVal[i] = Number(this.lastestPieVal[i])
              for (let j = 0; j < res.data.length; j++) {
                if (res.data[j].name === this.lastestPieKey[i]) {
                  this.comment = JSON.parse(res.data[j].comment)
                  for (let k in this.comment){
                    if (this.lastestPieVal[i] >= this.comment[k].start && this.lastestPieVal[i] < this.comment[k].end ) {
                      this.lastestBrief_exp.push(this.comment[k].brief_exp.replace("抑郁", "活力").replace("自尊", "身体平衡感").replace("风险倾向", "攻击性"))
                    }
                  }
                  break
                }
              }
            }

          })
        }
      }
    })
  },
  mounted () {
    this.isMounted = true
  }
}
</script>

<style lang="scss">
  .a-upload input{
    position: absolute;
    font-size: 1px;
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: pointer
  }
#data-list-list-view {

  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
